import React, { useState, useEffect } from "react";
import Panel from "../../../components/v2/panel";
import { Button, Paper, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../../../utils/consts";
import axios from "axios";
import { panelPageMargin } from "../../../utils/consts";
import { Alert, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import TextField from "@mui/material/TextField";
import EasyButton2 from "../../../components/v2/easyButton2";
import DeleteJournal from "../../../components/v2/deleteJournal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { emotionMap } from "../../../utils/consts";
export default function JournalDetails() {
  const navigate = useNavigate();
  const { journalID } = useParams();
  const [error, setError] = useState("");
  const [journal, setJournal] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteJournal, setDelete] = useState({ dialog: false, journalID: "" });
  const [editTitle, setEditTitle] = useState("");
  const [editContent, setEditContent] = useState("");

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  function BasicBreadcrumbs() {
    return (
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            href="/v2/client/moodJournal"
            onClick={() => navigate("/v2/client/moodJournal")}
          >
            All Journals
          </Link>
          <Typography sx={{ color: "text.primary" }}>{journalID}</Typography>
        </Breadcrumbs>
      </div>
    );
  }

  const editJournal = async () => {
    try {
      setError("");
      let res = await axios.put(
        `${BaseURL}/api/client/journals/editJournal`,
        { _id: journal._id, title: editTitle, content: editContent },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setEditTitle(res.data.title);
      setEditContent(res.data.content);
      setJournal(res.data);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BaseURL}/api/client/journals/journalDetails/${journalID}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setJournal(res.data);
        setEditTitle(res.data.title);
        setEditContent(res.data.content);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  }, []);
  return (
    <Stack sx={{ ...panelPageMargin }}>
      <Panel />
      {error && (
        <Alert sx={{ marginTop: 5 }} severity="error">
          {error}
        </Alert>
      )}
      <Stack sx={{ margin: { xs: 1, md: 5 } }} spacing={2}>
        <BasicBreadcrumbs />
        <Paper
          sx={{
            display: {
              md: "block",
            },
            padding: { xs: 2, md: 3 },
            borderRadius: 3,
            width: "100%",
            backgroundColor: emotionMap[journal.emotion]?.color + "80",
          }}
        >
          <Stack width={"100%"}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant="h5" fontWeight={"bold"} sx={{ mr: 2 }}>
                {emotionMap[journal.emotion]?.emoji}
              </Typography>
              <Typography sx={{ flexGrow: 1 }}>
                {moment(journal.timeStamp).format("h:mm A, DD/MM/YY")}
              </Typography>
              <Button
                sx={{
                  display: { xs: "none", md: "flex" },
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "#662d91",
                  px: 2,
                }}
                variant="contained"
                onClick={editJournal}
                disabled={
                  editTitle === journal.title && editContent === journal.content
                }
              >
                Save Changes
              </Button>
              <IconButton
                onClick={() =>
                  setDelete({
                    dialog: true,
                    journalID: journal._id,
                  })
                }
                aria-label="delete"
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
            <TextField
              id="title"
              name="title"
              placeholder="Give a title to your journal"
              variant="outlined"
              value={editTitle}
              fullWidth
              multiline
              margin="normal"
              sx={{ borderRadius: 3 }}
              InputProps={{
                sx: {
                  fontSize: { xs: "1rem", md: "1.4rem" },
                  borderRadius: 3,
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "GrayText",
                  },
                  fontWeight: 500,
                },
              }}
              onChange={(e) => {
                setEditTitle(e.target.value);
              }}
            />

            <TextField
              id="content"
              name="content"
              placeholder={emotionMap[journal.emotion]?.placeholder}
              helperText={emotionMap[journal.emotion]?.helperText}
              variant="outlined"
              minRows={8}
              maxRows={Infinity} // Maximum number of rows
              multiline
              value={editContent}
              onChange={(e) => {
                setEditContent(e.target.value);
              }}
              fullWidth
              margin="normal"
              spellCheck="true" // Correct way to enable spell checking
              InputProps={{
                sx: {
                  fontSize: { xs: "1.0rem", md: "1.4rem" }, // Adjust the font size as needed
                  borderRadius: 3,
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "GrayText",
                  },
                },
              }}
              sx={{
                "& .MuiInputBase-multiline": {
                  height: "100%", // Make textarea fill the available height
                  width: "100%",
                },
              }}
            />
          </Stack>
        </Paper>
        <Stack
          alignSelf={"center"}
          sx={{
            display: {
              md: "none",
              xs:
                editTitle !== journal.title || editContent !== journal.content
                  ? "flex"
                  : "none",
            },
            marginBottom: { xs: 2, md: 0 },
          }}
        >
          <EasyButton2
            width="220px"
            label="Save Journal"
            onClick={editJournal}
          ></EasyButton2>
        </Stack>
      </Stack>
      <DeleteJournal
        error={error}
        setError={setError}
        deleteJournal={deleteJournal}
        setDelete={setDelete}
      />
    </Stack>
  );
}
