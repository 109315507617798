import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useRef } from "react";
import {
  BaseURL,
  validateEmail,
  validateName,
  validatePhoneNo,
  validateUsername,
} from "../utils/consts";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import PasswordTextfield from "../components/passwordTextfield";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Loader } from "../components/Loader";
import EasyButton2 from "../components/v2/easyButton2";
import DoneIcon from "@mui/icons-material/Done";
import hero from "../utils/hero.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Logo from "../components/logo";
import Captcha from "../components/captcha";

export default function Signup() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNo: "",
    username: "",
    gender: "Female",
    age: null,
    isMarried: "",
    password: "",
    confirmPassword: "",
    acceptedTerms: false,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [openOtp, setOpenOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const openCaptcha = () => {
    setError("");
    if (validateName(user.name) !== "") {
      return setError(validateName(user.name));
    }
    if (!validateEmail(user.email)) {
      return setError("Please enter a valid email id");
    }
    if (!validatePhoneNo(user.phoneNo)) {
      return setError("Please enter a valid phone number");
    }
    if (validateUsername(user.username) !== "") {
      return setError(validateUsername(user.username));
    }
    if (typeof user.age !== "number" || !user.age || user.age <= 13) {
      return setError(
        "Age should be a number and greater than 13 years according to regulatory guidelines"
      );
    }
    if (!user.gender || user.gender === "Other") {
      setError("Gender is required");
      return;
    }
    if (user.isMarried === "") {
      setError("Marital status is required");
      return;
    }
    if (user.password.length < 6) {
      setError("Password must be atleast 6 characters long");
      return;
    }
    if (confirmPassword !== user.password) {
      setError("Password re-entry not matching");
      return;
    }
    if (!user.acceptedTerms) {
      setError("Please accept terms and conditions to proceed");
      return;
    }
    // if (captchaRef.current) {
    //   captchaRef.current.execute();
    // }
    sendOtp("");
  };
  const sendOtp = async (token) => {
    try {
      setError("");
      setOtp("");
      setLoading(true);
      await axios.post(`${BaseURL}/api/client/auth/sendSignupOtp`, {
        phoneNo: user.phoneNo,
        email: user.email,
        username: user.username,
        // captchaToken: token,
      });
      setLoading(false);
      setOpenOtp(true);
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.response?.data?.message) setError(err.response.data?.message);
      else setError(err.message);
    }
  };

  const verify = async (otpValue) => {
    try {
      setLoading(true);
      let res = await axios.post(
        `${BaseURL}/api/client/auth/verifyOtpAndSignup`,
        { otp: otpValue, ...user }
      );
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("username", res.data.username);
      setLoading(false);
      setOpenOtp(false);
      navigate("/v2/client/home", { replace: true });
    } catch (err) {
      console.log(err);
      if (err.response?.data?.message) setError(err.response?.data?.message);
      else setError(err.message);
      setOtp("");
      setLoading(false);
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <Stack
        direction="row"
        justifyContent={"space-around"}
        alignItems={"center"}
        paddingBottom={5}
        px={{ sm: 5 }}
      >
        <Stack
          sx={{
            paddingX: { xs: 5, lg: 10 },
            display: { xs: "none", md: "block" },
          }}
          alignItems={"center"}
          // marginTop={5}
          spacing={4}
          justifyContent={"center"}
          width={"100%"}
          // maxWidth={"700px"}
        >
          <Box
            component="img"
            alt="Happy Life"
            src={hero}
            sx={{
              width: { xs: "80%", md: "90%", lg: "70%" },
              mx: "auto !important", // Center horizontally
              display: "block",
              transition: "0.3s",
              "&:hover": {
                transform: "translateY(-8px)",
              },
            }}
          />
          <Typography variant="h6" textAlign={"center"}>
            "Step into a place where you're anonymously understood and
            supported."{" "}
          </Typography>
          <Stack alignItems={"self-start"} spacing={2}>
            {[
              "Expert Support: Connect with licensed professionals",
              "Confidential & Secure: Your privacy is paramount to us",
              "Anonymously chatting: Share your feelings 24/7 with AI support EasyBuddy.",
              "Crisis Intervention: In urgent situations, receive immediate assistance from our trained professionals.",
              "CBT based daily assessment: Engage in daily evaluations rooted in Cognitive Behavioral Therapy to track and improve your mental health.",
              "Mood Journals: Express and reflect on your feelings with our interactive mood-tracking journals.",
            ].map((e) => {
              return (
                <Stack
                  key={e}
                  direction={"row"}
                  justifyContent={"start"}
                  spacing={2}
                  padding={0.5}
                >
                  <DoneIcon /> <Typography> {e}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        <Stack
          width={"100%"}
          maxWidth={"700px"}
          sx={{ paddingX: { lg: 10 } }}
          padding={2}
          spacing={4}
        >
          <Stack gap={{ md: 0, xs: 2 }}>
            <Logo />
            <Typography fontSize={20}>
              Welcome to PeaceTwogether. Signup to your Account
            </Typography>
          </Stack>
          <TextField
            label="Enter full name"
            value={user.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            variant="outlined"
            margin="normal"
            helperText="Enter your full name. It will not be visible to consultants."
          />
          <TextField
            label="Enter email"
            autoComplete="off"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value.trim() })}
            variant="outlined"
            margin="normal"
            helperText="Enter your valid email. It will not be visible to consultants."
          />
          <TextField
            label="Enter 10 digit phone no.(whatsapp)"
            value={user.phoneNo}
            onChange={(e) => {
              setUser({ ...user, phoneNo: e.target.value.trim() });
              e.target.value.length !== 10
                ? setError("phone no needs to be of 10 digits")
                : setError("");
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            margin="normal"
            helperText="Enter your 10 digit whatsapp no. so that we can reach you."
          />
          <TextField
            label="Enter display name"
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
            helperText="Choose a username that will be visible to consultants. For example: BunnyRabbit"
            variant="outlined"
            margin="normal"
          />
          <TextField
            type="number"
            label="Enter age"
            InputProps={{
              inputProps: { min: 0, max: 120 },
              endAdornment: (
                <InputAdornment position="end">years old</InputAdornment>
              ),
            }}
            value={user.age}
            onChange={(e) => {
              e.target.value = e.target.value < 0 ? 0 : e.target.value;
              e.target.value = e.target.value > 100 ? 100 : e.target.value;
              setUser({ ...user, age: Number(e.target.value) });
            }}
            variant="outlined"
            margin="normal"
            helperText="Enter your age. It will be visible to consultants."
          />
          <TextField
            label="Enter gender"
            select
            value={
              ["Male", "Female", "Other"].includes(user.gender)
                ? user.gender
                : "Other"
            }
            onChange={(e) => setUser({ ...user, gender: e.target.value })}
            variant="outlined"
            margin="normal"
            helperText="Enter your gender. It will be visible to consultants."
          >
            {[
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
              { value: "Other", label: "Other" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {user.gender !== "Male" && user.gender !== "Female" && (
            <TextField
              label="Specify gender"
              value={user.gender === "Other" ? "" : user.gender}
              onChange={(e) => setUser({ ...user, gender: e.target.value })}
              variant="outlined"
              margin="normal"
            />
          )}
          <TextField
            label="Are you married?"
            select
            value={user.isMarried}
            onChange={(e) => setUser({ ...user, isMarried: e.target.value })}
            variant="outlined"
            margin="normal"
            helperText="Enter if you are married. It will be visible to consultants."
          >
            {[
              { value: true, label: "Married" },
              { value: false, label: "Unmarried" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <PasswordTextfield
            password={user.password}
            setPassword={(e) => setUser({ ...user, password: e })}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
          <PasswordTextfield
            id="confirmPassword"
            password={confirmPassword}
            setPassword={(e) => {
              setConfirmPassword(e);
            }}
            showPassword={showPassword}
            label="Confirm password"
            setShowPassword={setShowPassword}
          />
          <FormGroup>
            <FormControlLabel
              sx={{ display: "flex", alignItems: "start" }}
              control={
                <Checkbox
                  checked={user.acceptedTerms}
                  onChange={(e) =>
                    setUser({ ...user, acceptedTerms: e.target.checked })
                  }
                />
              }
              label={
                <Typography variant="body2" component="span" display="inline">
                  By using this application, you agree to the{" "}
                  <Link
                    href="https://peacetwogether.com/policies"
                    target="_blank"
                    color="primary"
                    underline="always"
                  >
                    Terms of Service
                  </Link>{" "}
                  of PeaceTwogether
                </Typography>
              }
            />
          </FormGroup>
          <Captcha
            captchaRef={captchaRef}
            onCaptchaSuccess={(token) => {
              sendOtp(token);
            }}
          />
          {error !== "" && <Alert severity="error">{error}</Alert>}
          <EasyButton2 label="Signup" size="small" onClick={openCaptcha} />
          <Typography variant="body2">
            Already have an account? <a href="/login">Login</a>
          </Typography>
        </Stack>
      </Stack>
      <Dialog open={openOtp} fullWidth>
        <DialogTitle variant="h5" textAlign={"center"}>
          Please enter one time password to verify your account
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"center"}>
            Code has been sent to {user.phoneNo}
            {/* +91 {user.phoneNo} */}
          </DialogContentText>
          <Stack alignItems={"center"} m={{ lg: 5, xs: 0 }} my={5}>
            {loading ? (
              <Loader height={"auto"} />
            ) : (
              <MuiOtpInput
                sx={{
                  gap: { xs: 0.5, md: 2 },
                  minWidth: "250px",
                  marginInline: "auto",
                  display: "flex",
                  maxWidth: "650px",
                  paddingY: { md: 0, xs: 2 },
                }}
                autoFocus
                length={6}
                value={otp}
                onChange={(e) => {
                  setOtp(e);
                  setError("");
                }}
                onComplete={(e) => verify(e)}
                TextFieldsProps={{
                  type: "number",
                  inputProps: {
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  },
                }}
              />
            )}{" "}
          </Stack>
          <Typography variant="body2">
            Didn't receive sms?{" "}
            <Button
              onClick={() =>
                // captchaRef.current.execute()
                sendOtp("")
              }
            >
              Resend Otp
            </Button>
          </Typography>
          {error !== "" && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            width={150}
            onClick={() => setOpenOtp(false)}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </>
  );
}
