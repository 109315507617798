import { Stack } from "@mui/material";
import ConsultantPieChart from "./admin/consultantsPie";
import StackedBarChart from "../stackedBarChart";
import { emotionMap } from "../../utils/consts";

export default function MoodChart({ data: { pieChart, stackedBarChart } }) {
  return (
    <Stack
      spacing={5}
      my={5}
      justifyContent={"center"}
      sx={{
        backgroundColor: "whitesmoke",
        padding: 3,
        borderRadius: "10px",
      }}
    >
      <ConsultantPieChart
        consultants={pieChart.length}
        label={"Mood Journals"}
        title={"Emotion Distribution"}
        colors={pieChart.map((d) => emotionMap[d.name].color)}
        data={pieChart}
        description={
          "This pie chart shows the distribution of emotions in the mood journal entries you submitted. Each slice of the pie represents the number of entries that have been tagged with a particular emotion."
        }
        height={{ md: "373px", xs: "600px" }}
      />
      <StackedBarChart
        data={stackedBarChart}
        yAxisDomain={"auto"}
        height={{ md: "373px", xs: "500px" }}
        title={<b>Monthly Breakup of Emotions</b>}
        description={
          "This stacked bar chart shows the monthly distribution of emotions in the mood journal entries you submitted. Each bar's color block represents the number of entries that have been tagged with a particular emotion in a given month."
        }
        stackLabels={[
          { dataKey: "Angry", stackId: "a", fill: "#fc6868" },
          { dataKey: "Confused", stackId: "a", fill: "#9e9696" },
          { dataKey: "Sad", stackId: "a", fill: "#a1a1ff" },
          { dataKey: "Neutral", stackId: "a", fill: "#D3D3D3" },
          { dataKey: "Happy", stackId: "a", fill: "#fac343" },
        ]}
      />
    </Stack>
  );
}
