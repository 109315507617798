import { Stack, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const labels = [
  { dataKey: "CBT Quiz", stackId: "a", fill: "#82ca9d" },
  { dataKey: "Stress Tracker", stackId: "a", fill: "#ffc658" },
  { dataKey: "Depression Tracker", stackId: "a", fill: "#ff7f50" },
  { dataKey: "Consultation", stackId: "a", fill: "#a0d468" },
];
export default function StackedBarChart({
  data,
  stackLabels = labels,
  yAxisDomain = [0, 100],
  title = (
    <>
      <b>Monthly Breakup of MWS Score</b> (Activity Wise)
    </>
  ),
  description = "The monthwise MWS score breakup of the user",
  height = { md: "343px", xs: "500px" },
}) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: height,
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        pb={2}
        sx={{ width: "100%" }}
      >
        {title}
      </Typography>{" "}
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: -30,
            bottom: 0,
          }}
          barSize={30} // Adjust the barSize value to make the bars thinner
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 10, color: "gray" }} />
          <YAxis
            label={{ value: "Score", angle: -90, position: "insideLeft" }}
            domain={yAxisDomain}
            tick={{ fontSize: 10, color: "gray" }}
          />
          <Tooltip />
          <Legend />
          {stackLabels.map((bar, index) => (
            <Bar
              key={index}
              dataKey={bar.dataKey}
              stackId={bar.stackId}
              fill={bar.fill}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <Typography sx={{ paddingY: 1 }} textAlign={"center"}>
        {description}
      </Typography>
    </Stack>
  );
}
