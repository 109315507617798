import {
  Alert,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  MenuItem,
  Menu,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import DeleteIcon from "@mui/icons-material/Delete";
import Panel from "../../../components/v2/panel";
import EasyButton2 from "../../../components/v2/easyButton2";
import MoodChart from "../../../components/v2/moodChart";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import DeleteJournal from "../../../components/v2/deleteJournal";
import { emotionMap } from "../../../utils/consts";
import NoRecordsImg from "../../../utils/noRecords.jpg";

const moods = [
  {
    emoji: "😡",
    color: "#fc6868", // Red
    emotion: "Angry",
  },
  {
    emoji: "🤨",
    color: "#9e9696", // Gray
    emotion: "Confused",
  },
  {
    emoji: "😕",
    color: "#a1a1ff", // Blue
    emotion: "Sad",
  },
  {
    emoji: "🙂",
    color: "#D3D3D3", // Light Gray
    emotion: "Neutral",
  },
  {
    emoji: "😊",
    color: "#fcf09d", // Yellow
    emotion: "Happy",
  },
];

export default function MoodJournal() {
  const [tab, setTab] = useState(0);
  const [journals, setJournals] = useState([]);
  const [deleteJournal, setDelete] = useState({ dialog: false, journalID: "" });
  const [newJournal, setNewJournal] = useState({ open: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [currentJournal, setCurrentJournal] = useState(null);
  const [emotionStats, setEmotionStats] = useState({
    pieChart: [],
    stackedBarChart: [],
  });

  const handleMenuOpen = (event, journal) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentJournal(journal); // Track the currently selected journal
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentJournal(null);
  };
  const fetchData = async () => {
    try {
      let res = await axios.get(`${BaseURL}/api/client/journals/journals`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setJournals(res.data.journals);
      setEmotionStats({
        pieChart: res.data.emotions,
        stackedBarChart: res.data.monthWiseEmotions,
      });
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const addJournal = async (emotion) => {
    try {
      let res = await axios.post(
        `${BaseURL}/api/client/journals/addJournal`,
        { title: "", content: "", emotion },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setJournals([res.data, ...journals]);
      navigate(`/v2/client/journal/${res.data._id}`);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  useEffect(() => {
    setNewJournal({ open: true });
    fetchData();
  }, []);

  return (
    <>
      <Stack sx={{ ...panelPageMargin }}>
        <Panel />
        {error && (
          <Alert sx={{ marginTop: 5 }} severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            width: "100%",
            paddingY: { xs: 2, md: 5 },
            paddingX: { xs: 2, md: 5 },
          }}
        >
          <Tabs
            value={tab}
            onChange={(_, newValue) => setTab(newValue)}
            aria-label="MWS tabs"
            sx={{ mt: 0, p: 0 }}
          >
            <Tab label="My Journals" value={0} />
            <Tab label="Emotion Stats" value={1} />
          </Tabs>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={{ xs: "end", md: "space-between" }}
            paddingX={{ xs: 2, md: 5 }}
            paddingTop={{ xs: 2, md: 5 }}
          >
            <Typography
              variant="h5"
              display={{ xs: "none", md: "block" }}
              fontWeight={"bold"}
              color={"#143F6B"}
            >
              {tab === 0 ? "My Journals" : "Emotion Statistics"}
            </Typography>
            <EasyButton2
              width="200px"
              label="Write a Journal"
              onClick={() => setNewJournal({ open: true })}
            ></EasyButton2>
          </Stack>
          {tab === 0 && (
            <MyJournals
              journals={journals}
              anchorEl={anchorEl}
              currentJournal={currentJournal}
              handleMenuClose={handleMenuClose}
              handleMenuOpen={handleMenuOpen}
              setDelete={setDelete}
              setNewJournal={setNewJournal}
              navigate={navigate}
            />
          )}
          {tab === 1 && <MoodChart data={emotionStats} />}
        </Box>
      </Stack>
      <DeleteJournal
        deleteJournal={deleteJournal}
        setDelete={setDelete}
        error={error}
        setError={setError}
        setJournals={setJournals}
      />
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            maxWidth: "950px", // This sets the max width but won't make it responsive
            width: "90%", // This might not be respected due to Material-UI internal styling
          },
        }}
        open={newJournal.open}
        onClose={() => setNewJournal({ open: false })}
      >
        {AskFeeling(addJournal, setNewJournal)}
      </Dialog>
    </>
  );
}
function AskFeeling(addJournal, setNewJournal) {
  return (
    <Stack
      sx={{
        position: "relative",
        alignItems: "center",
        padding: { xs: 3, md: 5 },
        borderBottom: "0.5px solid rgba(20,63,107,0.3) ",
        backgroundColor: "floralwhite",
      }}
    >
      <IconButton
        size="large"
        onClick={() => setNewJournal({ open: false })}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" color={"#143F6B"}>
        Hello, {localStorage.getItem("username")}
      </Typography>
      <Typography
        variant="h5"
        fontSize={{ xs: "1.1rem", md: "2rem" }}
        fontWeight={"bold"}
        color={"#143F6B"}
        mb={2}
      >
        How are you feeling today?
      </Typography>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        gap={{ xs: 0, sm: 2, md: 2 }}
      >
        {moods.map((j, i) => (
          <Paper
            key={i}
            sx={{
              display: "flex",
              width: { xs: "220px", md: "160px" },
              alignItems: "center",
              padding: 2,
              marginY: 1,
              cursor: "pointer",
              borderRadius: 3,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: j.color + "83",
                transform: "scale(1.08)",
              },
            }}
            onClick={() => addJournal(j.emotion)}
          >
            <Typography textAlign={"center"} variant="h4" color={"#143F6B"}>
              {j.emoji}
            </Typography>
            <Typography
              textAlign={"center"}
              variant="h6"
              color={"#143F6B"}
              ml={2}
            >
              {j.emotion}
            </Typography>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
}
function MyJournals({
  journals,
  anchorEl,
  currentJournal,
  handleMenuClose,
  handleMenuOpen,
  setDelete,
  setNewJournal,
  navigate,
}) {
  if (journals.length === 0) {
    return <NoRecord setNewJournal={setNewJournal} />;
  }
  return (
    <Grid
      container
      spacing={2}
      gap={{ xs: 2, lg: 4 }}
      my={{ xs: 1, md: 2 }}
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      {journals.map((j, i) => {
        return (
          <Paper
            sx={{
              position: "relative",
              padding: 3,
              marginY: 1,
              marginX: 1,
              borderRadius: 3,
              width: { xs: "90%", md: "380px" },
              height: "220px",
              cursor: "pointer",
              backgroundColor: j.emotion
                ? emotionMap[j.emotion]?.color + "80"
                : "floralwhite",
            }}
            key={i}
            onClick={() => navigate(`/v2/client/journal/${j._id}`)}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={2}
            >
              {" "}
              <Typography variant="h6">
                <span style={{ fontSize: 25 }}>
                  {" "}
                  {emotionMap[j.emotion]?.emoji}
                </span>{" "}
                {j.emotion}
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                width={"fit-content"}
                borderRadius={1}
              >
                <IconButton sx={{ zIndex: 10 }} color="inherit">
                  <AccessTimeIcon sx={{ fontSize: 20 }} />
                </IconButton>{" "}
                <Typography fontSize={16} pr={1}>
                  {moment(j.timeStamp).format("DD MMM YY")}
                </Typography>
              </Stack>
            </Stack>
            {j.title ? (
              <Typography fontWeight={"bold"}>Title: {j.title}</Typography>
            ) : (
              <Typography color={"GrayText"}>Title: No Title</Typography>
            )}

            {j.content ? (
              <Typography>
                {"Journal: " +
                  j.content.substring(0, 60) +
                  (j.content.length > 60 ? "..." : "")}
              </Typography>
            ) : (
              <Typography color={"GrayText"}>Journal: No Journal</Typography>
            )}
            <IconButton
              sx={{
                position: "absolute",
                bottom: 5,
                right: 5,
              }}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuOpen(event, j)}
            >
              <MoreVertIcon />
            </IconButton>
          </Paper>
        );
      })}
      {currentJournal && (
        <JournalMenu
          journal={currentJournal}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          setDelete={setDelete}
          navigate={navigate}
        />
      )}
    </Grid>
  );
}
function NoRecord({ setNewJournal }) {
  return (
    <Stack alignItems={"center"} width={"100%"} mb={10}>
      <Box component={"img"} src={NoRecordsImg} sx={{ width: "250px" }} />
      <Typography color={"#143F6B"} textAlign={"center"} mt={2}>
        No Journals Found
      </Typography>
      <Typography
        variant="h6"
        fontWeight={"bold"}
        color={"#143F6B"}
        textAlign={"center"}
        gutterBottom
        px={10}
      >
        Writing a mood journal can help you track your emotions, identify
        patterns and gain insights into your mental health. It can also help you
        develop a more positive mindset, practice gratitude and improve your
        overall well-being.
      </Typography>
      <EasyButton2
        label="Write a new journal"
        width={"250px"}
        onClick={() => setNewJournal({ open: true })}
      />
    </Stack>
  );
}
function JournalMenu({ journal, anchorEl, onClose, setDelete, navigate }) {
  return (
    anchorEl && (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        TransitionComponent={Fade}
        transitionDuration={100}
        sx={{
          boxShadow: "none",
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/v2/client/journal/${journal._id}`);
            onClose();
          }}
        >
          <EditIcon sx={{ mr: 1, fontSize: 18 }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
          }}
        >
          <ShareIcon sx={{ mr: 1, fontSize: 18 }} />
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDelete({
              dialog: true,
              journalID: journal._id,
            });
            onClose();
          }}
        >
          <DeleteIcon sx={{ mr: 1, fontSize: 18 }} />
          Delete
        </MenuItem>
      </Menu>
    )
  );
}
