import { colors, Stack, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
const CustomLegend = ({ data, colors }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        flexWrap: "wrap",
        marginRight: "20px",
        gap: "2px",
      }}
    >
      {data?.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px",
            width: "200px",
          }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: colors[index],
              marginRight: "5px",
              boxShadow: "0px 0px 5px 0px rgba(1,0,0,0.1)",
            }}
          ></div>
          <span style={{ color: "black", paddingLeft: "1px", flexGrow: 1 }}>
            {data && data[index].name}
          </span>
          <span style={{ margin: "auto" }}>{data && data[index].value}</span>
        </div>
      ))}
    </div>
  );
};

const COLORS = [
  "#f6eef2",
  "#687495",
  "#20B2AA",
  "#f79bbc",
  "#233a5a",
  "#2196F3",
];

const ConsultantPieChart = ({
  consultants,
  label,
  title,
  colors = COLORS,
  data,
  description,
  height = { md: "343px", xs: "500px" },
}) => {
  const CenteredLabel = ({ cx, cy }) => {
    return (
      <>
        <text
          x={cx}
          y={cy - 10}
          style={{ fontSize: "25px" }}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {consultants}
        </text>
        <text
          x={cx}
          y={cy + 10}
          style={{ fontSize: "12px", fill: "gray" }}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {label || "Consultants"}{" "}
        </text>
      </>
    );
  };
  return (
    <Stack
      sx={{
        width: "100%",
        height: height,
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        pb={{ md: 2 }}
        sx={{ width: "100%" }}
      >
        <b> {title} </b>
      </Typography>{" "}
      <Stack
        alignItems={"center"}
        justifyContent={"space-around"}
        direction={{ md: "row" }}
        height={"100%"}
      >
        <PieChart width={220} height={220}>
          <Pie
            data={data}
            cx={110}
            cy={110}
            labelLine={false}
            label={<CenteredLabel />}
            innerRadius={60}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
          >
            {[{}, {}, {}, {}, {}, {}].map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <CustomLegend colors={colors} data={data} />
      </Stack>
      {description && (
        <Typography sx={{ paddingY: 1 }} textAlign={"center"}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default ConsultantPieChart;
